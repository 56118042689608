import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _8f24db3a = () => interopDefault(import('../pages/forget_password.vue' /* webpackChunkName: "pages/forget_password" */))
const _bbe47fe0 = () => interopDefault(import('../pages/inspire.vue' /* webpackChunkName: "pages/inspire" */))
const _42e5ac9f = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _09a8f176 = () => interopDefault(import('../pages/reset_password.vue' /* webpackChunkName: "pages/reset_password" */))
const _08b2a3fb = () => interopDefault(import('../pages/administration/bus_stops.vue' /* webpackChunkName: "pages/administration/bus_stops" */))
const _f6511112 = () => interopDefault(import('../pages/administration/buses.vue' /* webpackChunkName: "pages/administration/buses" */))
const _b7978a58 = () => interopDefault(import('../pages/administration/drivers.vue' /* webpackChunkName: "pages/administration/drivers" */))
const _c01b51c8 = () => interopDefault(import('../pages/administration/roads.vue' /* webpackChunkName: "pages/administration/roads" */))
const _307dc55e = () => interopDefault(import('../pages/administration/users.vue' /* webpackChunkName: "pages/administration/users" */))
const _eb58ec0c = () => interopDefault(import('../pages/notifications/overview.vue' /* webpackChunkName: "pages/notifications/overview" */))
const _01348052 = () => interopDefault(import('../pages/overview/buses.vue' /* webpackChunkName: "pages/overview/buses" */))
const _11cc6ca6 = () => interopDefault(import('../pages/overview/daily_report.vue' /* webpackChunkName: "pages/overview/daily_report" */))
const _3de8a9b6 = () => interopDefault(import('../pages/overview/passengers.vue' /* webpackChunkName: "pages/overview/passengers" */))
const _3fdc839a = () => interopDefault(import('../pages/overview/reservations.vue' /* webpackChunkName: "pages/overview/reservations" */))
const _5abf7ea4 = () => interopDefault(import('../pages/system/agents.vue' /* webpackChunkName: "pages/system/agents" */))
const _62cc7740 = () => interopDefault(import('../pages/system/areas.vue' /* webpackChunkName: "pages/system/areas" */))
const _b2b01f62 = () => interopDefault(import('../pages/system/availabilities.vue' /* webpackChunkName: "pages/system/availabilities" */))
const _8ab3c656 = () => interopDefault(import('../pages/system/depots.vue' /* webpackChunkName: "pages/system/depots" */))
const _4f1b29b2 = () => interopDefault(import('../pages/system/holiday.vue' /* webpackChunkName: "pages/system/holiday" */))
const _623c71a9 = () => interopDefault(import('../pages/system/operators.vue' /* webpackChunkName: "pages/system/operators" */))
const _4a055ae0 = () => interopDefault(import('../pages/system/prices.vue' /* webpackChunkName: "pages/system/prices" */))
const _538ad039 = () => interopDefault(import('../pages/system/statistics.vue' /* webpackChunkName: "pages/system/statistics" */))
const _a20ec2f0 = () => interopDefault(import('../pages/system/status.vue' /* webpackChunkName: "pages/system/status" */))
const _8694f366 = () => interopDefault(import('../pages/systemlog/overview.vue' /* webpackChunkName: "pages/systemlog/overview" */))

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/forget_password/",
    component: _8f24db3a,
    pathToRegexpOptions: {"strict":true},
    name: "forget_password"
  }, {
    path: "/inspire/",
    component: _bbe47fe0,
    pathToRegexpOptions: {"strict":true},
    name: "inspire"
  }, {
    path: "/login/",
    component: _42e5ac9f,
    pathToRegexpOptions: {"strict":true},
    name: "login"
  }, {
    path: "/reset_password/",
    component: _09a8f176,
    pathToRegexpOptions: {"strict":true},
    name: "reset_password"
  }, {
    path: "/administration/bus_stops/",
    component: _08b2a3fb,
    pathToRegexpOptions: {"strict":true},
    name: "administration-bus_stops"
  }, {
    path: "/administration/buses/",
    component: _f6511112,
    pathToRegexpOptions: {"strict":true},
    name: "administration-buses"
  }, {
    path: "/administration/drivers/",
    component: _b7978a58,
    pathToRegexpOptions: {"strict":true},
    name: "administration-drivers"
  }, {
    path: "/administration/roads/",
    component: _c01b51c8,
    pathToRegexpOptions: {"strict":true},
    name: "administration-roads"
  }, {
    path: "/administration/users/",
    component: _307dc55e,
    pathToRegexpOptions: {"strict":true},
    name: "administration-users"
  }, {
    path: "/notifications/overview/",
    component: _eb58ec0c,
    pathToRegexpOptions: {"strict":true},
    name: "notifications-overview"
  }, {
    path: "/overview/buses/",
    component: _01348052,
    pathToRegexpOptions: {"strict":true},
    name: "overview-buses"
  }, {
    path: "/overview/daily_report/",
    component: _11cc6ca6,
    pathToRegexpOptions: {"strict":true},
    name: "overview-daily_report"
  }, {
    path: "/overview/passengers/",
    component: _3de8a9b6,
    pathToRegexpOptions: {"strict":true},
    name: "overview-passengers"
  }, {
    path: "/overview/reservations/",
    component: _3fdc839a,
    pathToRegexpOptions: {"strict":true},
    name: "overview-reservations"
  }, {
    path: "/system/agents/",
    component: _5abf7ea4,
    pathToRegexpOptions: {"strict":true},
    name: "system-agents"
  }, {
    path: "/system/areas/",
    component: _62cc7740,
    pathToRegexpOptions: {"strict":true},
    name: "system-areas"
  }, {
    path: "/system/availabilities/",
    component: _b2b01f62,
    pathToRegexpOptions: {"strict":true},
    name: "system-availabilities"
  }, {
    path: "/system/depots/",
    component: _8ab3c656,
    pathToRegexpOptions: {"strict":true},
    name: "system-depots"
  }, {
    path: "/system/holiday/",
    component: _4f1b29b2,
    pathToRegexpOptions: {"strict":true},
    name: "system-holiday"
  }, {
    path: "/system/operators/",
    component: _623c71a9,
    pathToRegexpOptions: {"strict":true},
    name: "system-operators"
  }, {
    path: "/system/prices/",
    component: _4a055ae0,
    pathToRegexpOptions: {"strict":true},
    name: "system-prices"
  }, {
    path: "/system/statistics/",
    component: _538ad039,
    pathToRegexpOptions: {"strict":true},
    name: "system-statistics"
  }, {
    path: "/system/status/",
    component: _a20ec2f0,
    pathToRegexpOptions: {"strict":true},
    name: "system-status"
  }, {
    path: "/systemlog/overview/",
    component: _8694f366,
    pathToRegexpOptions: {"strict":true},
    name: "systemlog-overview"
  }, {
    path: "/",
    redirect: "/overview/buses/",
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
